/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import CloseButton from 'components/atoms/CloseButton';

import Drawer, { DrawerContext, DrawerIds } from '../Drawer';

const testIds = {
    content: 'request-details-drawer-content',
    title: 'request-details-drawer-title',
    closeButton: 'request-details-drawer-close-button',
} as const;

export { testIds as RequestDetailsDrawerTestIds };

const RequestDetailsDrawer: React.FC = () => {
    const { isOpenDrawer, closeDrawer } = React.useContext(DrawerContext);
    const closeCurrentDrawerHandler = () => {
        closeDrawer(DrawerIds.RequestDetails);
    };
    return (
        <Drawer
            isOpen={isOpenDrawer(DrawerIds.RequestDetails)}
            onClose={closeCurrentDrawerHandler}
            widthStyle='w-[447px]'
        >
            <div className='divide-y divide-solid'>
                <div className='flex items-center justify-between border-b border-b-gray-300 p-2'>
                    <span
                        className='text-lg font-bold text-siemens-blue-7'
                        data-testid={testIds.title}
                    >
                        Request
                    </span>

                    <CloseButton
                        data-testid={testIds.closeButton}
                        onClick={closeCurrentDrawerHandler}
                    ></CloseButton>
                </div>
                <div className='bg-gray-100 p-2' data-testid={testIds.content}>
                    <h2>Request content</h2>
                </div>
            </div>
        </Drawer>
    );
};

export default RequestDetailsDrawer;
