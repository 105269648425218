/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
const TooltipIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
        <g>
            <path
                d='M7.99987 15.4668C12.1236 15.4668 15.4665 12.1238 15.4665 8.00011C15.4665 3.87639 12.1236 0.533447 7.99987 0.533447C3.87614 0.533447 0.533203 3.87639 0.533203 8.00011C0.533203 12.1238 3.87614 15.4668 7.99987 15.4668Z'
                fill='#55A0B9'
                stroke='#464646'
                strokeMiterlimit='10'
            />
            <path d='M8 11.7332V6.3999' stroke='white' strokeMiterlimit='10' />
            <path
                d='M7.99922 4C8.2119 4.00252 8.41505 4.08864 8.56475 4.23973C8.71445 4.39083 8.79867 4.59477 8.79922 4.80747C8.79922 5.01964 8.71493 5.22312 8.5649 5.37315C8.41487 5.52318 8.21139 5.60747 7.99922 5.60747C7.78705 5.60747 7.58356 5.52318 7.43353 5.37315C7.2835 5.22312 7.19922 5.01964 7.19922 4.80747C7.19949 4.59469 7.28362 4.39059 7.43338 4.23943C7.58314 4.08827 7.78645 4.00225 7.99922 4Z'
                fill='white'
            />
        </g>
    </svg>
);

export default TooltipIcon;
