/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import { PluginData } from 'types/PluginData';

export interface ISupplyChainContext {
    setCurrentComponent: (componentData: PluginData) => void;
    clear: () => void;
    currentComponent?: PluginData;
}

export const SupplyChainContext = React.createContext<ISupplyChainContext>({} as any);

export const SupplyChainContextProvider = ({ children }: React.PropsWithChildren) => {
    const [currentComponent, setCurrentComponent] = React.useState<PluginData>();

    const clear = () => {
        setCurrentComponent(undefined);
    };

    return (
        <SupplyChainContext.Provider value={{ setCurrentComponent, clear, currentComponent }}>
            {children}
        </SupplyChainContext.Provider>
    );
};
