/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { apiMockedService } from 'mocks';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { isDevelopment, isMockingActive } from 'const';

import App from './App';

// isDevelopment is necessary for tree-shaking
if (isDevelopment) {
    console.debug('Development mode');
    console.debug('NODE_ENV', process.env.NODE_ENV);

    if (isMockingActive) {
        console.debug('Mocks enabled');
        apiMockedService.initialize();
    }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
