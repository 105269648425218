/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { ComponentProps } from 'react';

import CircularProgress from 'components/atoms/CircularProgress';
import Message from 'components/atoms/Message';

const FetchMessage = (props: Omit<ComponentProps<typeof Message>, 'children'>) => {
    return (
        <Message {...props}>
            <div>
                <CircularProgress />
                <h2 className='mt-1 text-xl'>Fetching Data...</h2>
            </div>
        </Message>
    );
};

export default FetchMessage;
