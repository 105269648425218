/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import { CloseIcon } from 'assets';

const CloseButton = (props: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>) => {
    const finalClassName = props.className ?? '';
    return (
        <div {...props} className={`cursor-pointer ${finalClassName}`.trim()}>
            <CloseIcon />
        </div>
    );
};

export default CloseButton;
