/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
const CircularProgressIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <g>
            <circle cx='16' cy='16' r='15' stroke='#E6E6E6' strokeWidth='2' />
            <mask id='path-2-inside-1_1383_9227' fill='white'>
                <path d='M6.44064 27.5768C6.09372 27.9969 6.15146 28.6215 6.59217 28.9419C9.73944 31.23 13.6244 32.2995 17.5203 31.9276C21.7445 31.5244 25.6356 29.4596 28.3375 26.1875C31.0394 22.9154 32.3308 18.704 31.9276 14.4797C31.5558 10.5838 29.7706 6.97136 26.9285 4.31376C26.5305 3.94162 25.9063 4.00307 25.5594 4.42321C25.2124 4.84335 25.2746 5.46278 25.6693 5.83835C28.1095 8.16011 29.6413 11.2924 29.9634 14.6672C30.3169 18.3705 29.1848 22.0626 26.8161 24.9312C24.4474 27.7998 21.0361 29.6099 17.3328 29.9634C13.958 30.2855 10.5925 29.3739 7.85103 27.417C7.40756 27.1005 6.78756 27.1567 6.44064 27.5768Z' />
            </mask>
            <path
                d='M6.44064 27.5768C6.09372 27.9969 6.15146 28.6215 6.59217 28.9419C9.73944 31.23 13.6244 32.2995 17.5203 31.9276C21.7445 31.5244 25.6356 29.4596 28.3375 26.1875C31.0394 22.9154 32.3308 18.704 31.9276 14.4797C31.5558 10.5838 29.7706 6.97136 26.9285 4.31376C26.5305 3.94162 25.9063 4.00307 25.5594 4.42321C25.2124 4.84335 25.2746 5.46278 25.6693 5.83835C28.1095 8.16011 29.6413 11.2924 29.9634 14.6672C30.3169 18.3705 29.1848 22.0626 26.8161 24.9312C24.4474 27.7998 21.0361 29.6099 17.3328 29.9634C13.958 30.2855 10.5925 29.3739 7.85103 27.417C7.40756 27.1005 6.78756 27.1567 6.44064 27.5768Z'
                stroke='#005F87'
                strokeWidth='4'
                mask='url(#path-2-inside-1_1383_9227)'
            />
        </g>
    </svg>
);

export default CircularProgressIcon;
