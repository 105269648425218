/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useQuery } from '@tanstack/react-query';

import { DownloadKeyResponse, InitCacheForDownloadRequest } from 'types/Download';

import apiRoutes from './apiRoutes';
import { api } from './helper';

export const useGetDownloadKeyQuery = (
    apiRegionRoot: string,
    supplierPartNumber: string,
    enabled: boolean,
) =>
    useQuery<DownloadKeyResponse>({
        queryKey: ['getDownloadKey', supplierPartNumber],
        queryFn: async () =>
            api.getJson<DownloadKeyResponse>(apiRegionRoot, apiRoutes.getDownloadKey),
        enabled: enabled,
        cacheTime: Infinity,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    });

export const useGetInitCacheForDownloadQuery = (
    apiRegionRoot: string,
    postData: InitCacheForDownloadRequest,
    enabled: boolean,
) =>
    useQuery<void>({
        queryKey: ['getInitCacheForDownload', postData.partToPartition[0].partId],
        queryFn: async () => {
            return api.post<void>(apiRegionRoot, apiRoutes.postInitCacheForDownload, postData);
        },
        enabled: !!postData.downloadKey && enabled,
        cacheTime: Infinity,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    });
