/*
 * Unpublished work. Copyright 2023 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import useDebounce from 'hooks/useDebounce';

export interface SearchContextType {
    value: string;
    debouncedValue: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    setDebouncedValue: React.Dispatch<React.SetStateAction<string>>;
    clear: () => void;
}

export const SearchContext = React.createContext<SearchContextType>({
    value: '',
    debouncedValue: '',
    setValue: () => {},
    setDebouncedValue: () => {},
    clear: () => {},
});

export const SearchContextProvider = ({ children }: React.PropsWithChildren) => {
    const { clear, debouncedValue, value, setValue, setDebouncedValue } = useDebounce('', 5000);

    return (
        <SearchContext.Provider
            value={{
                clear,
                debouncedValue: debouncedValue.replace(/^\s+$/, ''),
                setDebouncedValue,
                value,
                setValue,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export default SearchContextProvider;
