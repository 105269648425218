/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */

// GENERAL
export const isDevelopment = import.meta.env.MODE === 'development';
export const isMockingActive = import.meta.env.VITE_MOCKS === 'true';

// DEFAULT API
export const apiRoot = (import.meta.env.VITE_API_ROOT as string) || '';

// REGION API
const apiRegionString = import.meta.env.VITE_API_REGION_MAP || '{}';
export const apiRegionMap: Record<string, string> = JSON.parse(apiRegionString);

// SAN
export const sanProductKey = (import.meta.env.VITE_SAN_PRODUCT_KEY as string) || '';
export const sanInternalUsage = (import.meta.env.VITE_SAN_INTERNAL_USAGE as string) === 'true';
