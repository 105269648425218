/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import Cookies from 'js-cookie';
import React, { useContext } from 'react';
import { siemensAnalyticsEvents } from 'services/SiemensAnalyticsService';

import { ApiRegionContext } from 'api/ApiRegionContextProvider';
import { useGetDownloadKeyQuery, useGetInitCacheForDownloadQuery } from 'api/dragService';
import { apiRoot } from 'const';
import { InitCacheForDownloadRequest } from 'types/Download';
import { PluginData } from 'types/PluginData';

const encodeUrl = (url: string, data: string) => `${apiRoot}${url}${encodeURIComponent(data)}`;

const getDragData = (queryObj: InitCacheForDownloadRequest) => {
    const callEdx = encodeUrl('/api/v2/download/parts/edx?x=', JSON.stringify(queryObj));
    const callLog = encodeUrl('/api/v2/download/tool/spn/report?k=', queryObj.uniqueId);
    const cookieKeysAndValuesArray = Object.entries(Cookies.get()).map(([k, v]) => `${k}=${v}`);
    const cookieHeader = { name: 'Cookie', value: cookieKeysAndValuesArray.join(';') };
    return JSON.stringify({
        source: 'PartQuest',
        urls: [{ edx: callEdx, log: callLog }],
        v2: {
            source: 'PartQuest',
            urls: [
                {
                    edx: {
                        url: callEdx,
                        headers: [cookieHeader],
                    },
                    log: {
                        url: callLog,
                    },
                },
            ],
        },
    });
};

const createAnalyticsEvent = (data: PluginData) =>
    siemensAnalyticsEvents.createDownloadEvent({
        'CAD Source': data.dataAvailability.source,
        'Part Category': data.category.name,
        Supplier: data.manufacturer.name,
        'Supplier Part Number': data.mfrPartNumber,
    });

const useDragButton = (data: PluginData) => {
    const [enabled, setEnabled] = React.useState(false);
    const { apiRegionRoot } = useContext(ApiRegionContext);

    const downloadKeyQuery = useGetDownloadKeyQuery(
        apiRegionRoot,
        data?.supplierPartNumber,
        enabled,
    );

    const queryObj: InitCacheForDownloadRequest = {
        partToPartition: [
            {
                dataSource: 'Supplyframe',
                partId: data?.supplierPartNumber,
                partition: 'PartQuest',
            },
        ],
        flow: 'PADS PRO',
        downloadKey: downloadKeyQuery.data?.downloadKey,
        uniqueId:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
    };

    useGetInitCacheForDownloadQuery(apiRegionRoot, queryObj, enabled);

    const analyticsEvent = createAnalyticsEvent(data);

    return {
        queryObj,
        setEnabled,
        getDragData,
        analyticsEvent,
    };
};

export default useDragButton;
