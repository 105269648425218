/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useQuery } from '@tanstack/react-query';

import apiRoutes from './apiRoutes';
import { api } from './helper';

export const useGetRegionQuery = (apiRegionRoot: string, retry = true) =>
    useQuery<string>({
        queryKey: ['getRegionQuery'],
        queryFn: async () => api.getJson<string>(apiRegionRoot, apiRoutes.getExtractRegion),
        refetchOnWindowFocus: false,
        retry: retry,
    });
