/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

const Button: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    return (
        <button
            {...props}
            className={`flex cursor-pointer rounded-sm bg-siemens-blue-7 px-2 py-1 font-semibold text-white ${props.className}`.trim()}
        >
            {props.children}
        </button>
    );
};

export default Button;
