/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

export const useDrawer = (initialOpenState: boolean) => {
    const [isOpen, setIsOpen] = React.useState(initialOpenState);

    const closeDrawerHandler = () => {
        setIsOpen(false);
    };
    const openDrawerHandler = () => {
        setIsOpen(true);
    };

    return { isOpen, closeDrawerHandler, openDrawerHandler };
};
