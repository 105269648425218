/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export const getUrl = (baseUrl: string, url: string) => `${baseUrl}${url}`;

const headers = {
    'content-type': 'application/json',
};

export const api = {
    getJson: async <T>(baseUrl: string, url: string, params?: object) => {
        if (typeof params === 'object') {
            const urlObj = new URL(url);
            urlObj.search = new URLSearchParams(params as URLSearchParams).toString();
        }

        const response = await fetch(getUrl(baseUrl, url), {
            method: 'GET',
            headers: headers,
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return response.json() as Promise<T>;
    },
    post: async <T>(baseUrl: string, url: string, data: any) => {
        return (
            await fetch(getUrl(baseUrl, url), {
                method: 'POST',
                body: JSON.stringify(data),
                headers: headers,
                credentials: 'include',
            })
        ).text() as Promise<T>;
    },
    postJson: async <T>(baseUrl: string, url: string, data: any) => {
        return (
            await fetch(getUrl(baseUrl, url), {
                method: 'POST',
                body: JSON.stringify(data),
                headers: headers,
                credentials: 'include',
            })
        ).json() as Promise<T>;
    },
    patch: async <T>(baseUrl: string, url: string, data: any) => {
        return (
            await fetch(getUrl(baseUrl, url), {
                method: 'PATCH',
                body: JSON.stringify(data),
                headers: headers,
                credentials: 'include',
            })
        ).json() as Promise<T>;
    },
    delete: async <T>(baseUrl: string, url: string) => {
        return (
            await fetch(getUrl(baseUrl, url), {
                method: 'DELETE',
                headers: headers,
                credentials: 'include',
            })
        ).json() as Promise<T>;
    },
};
