/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { siemensAnalyticsEvents, siemensAnalyticsService } from 'services/SiemensAnalyticsService';

import { PqLogoIcon } from 'assets';
import ClearButton from 'components/molecules/ClearButton';
import SearchInput from 'components/molecules/SearchInput';
import SupplyChainDrawer from 'components/templates/SupplyChainDrawer';
import { isDevelopment, sanInternalUsage, sanProductKey } from 'const';

const testIds = {
    main: 'layout-main',
} as const;

export { testIds as LayoutTestIds };

const Layout = ({ children }: React.PropsWithChildren) => {
    const isAnalyticsInitialized = React.useRef(false);

    React.useEffect(() => {
        if (!isDevelopment) {
            return;
        }

        if (isAnalyticsInitialized.current) {
            return;
        }

        try {
            const initParameters: siemensAnalyticsService.InitParameters = {
                customerId: '1',
                userId: '2',
                productKey: sanProductKey,
                userEmail: '4',
                internalUsage: sanInternalUsage,
                sessionTimeoutInSec: 600,
                productTier: 'test',
                gatherPIIData: false,
                environment: 'Development',
            };

            siemensAnalyticsService.init(initParameters);
            siemensAnalyticsService.logEvent(siemensAnalyticsEvents.createInitializationEvent());
        } catch (e) {
        } finally {
            isAnalyticsInitialized.current = true;
        }
    }, []);

    return (
        <>
            <main
                data-testid={testIds.main}
                className='relative flex h-screen w-full min-w-[160px] flex-col px-1'
            >
                <div className='flex items-center py-3'>
                    <a href='https://partquest.com/' className='pl-2 pr-1.5'>
                        <PqLogoIcon />
                    </a>
                    <SearchInput className='flex-grow' />
                    <ClearButton className='pl-1.5 pr-1' />
                </div>
                <div className='w-full overflow-y-scroll pl-2 pr-1'>{children}</div>
            </main>

            <SupplyChainDrawer />
        </>
    );
};

export default Layout;
