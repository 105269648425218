/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import { DownloadButton } from 'components/atoms/DownloadButton/DownloadButton';
import DragButton from 'components/molecules/DragButton';
import EcadIcons from 'components/molecules/EcadIcons';
import { SourceAndDescriptionTooltip } from 'components/molecules/SourceAndDescriptionTooltip/SourceAndDescriptionTooltip';
import { SourceElement } from 'components/molecules/SourceElement/SourceElement';
import { PluginData } from 'types/PluginData';

interface Props {
    data: PluginData;
}

const testIds = {
    dragButtonContainer: 'component-vertical-info-drag-button-container',
    manufacturerContainer: 'component-vertical-info-manufacturer-container',
    mfrPartNumber: 'component-vertical-info-mfr-part-number',
    manufacturerName: 'component-vertical-info-manufacturer-name',
    tooltipContainer: 'component-vertical-info-tooltip-container',
    sourceContainer: 'component-vertical-info-source-container',
    descriptionContainer: 'component-vertical-info-description-container',
    description: 'component-vertical-info-description',
    availabilityContainer: 'component-vertical-info-availability-container',
    downloadButtonContainer: 'component-vertical-info-download-button-container',
} as const;

export { testIds as componentVerticalInfoTestIds };

export const ComponentVerticalInfo = ({
    data,
    ...props
}: Props & Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'>) => (
    <div {...props} className='flex'>
        <div className='p-2' data-testid={testIds.dragButtonContainer}>
            <DragButton data={data} />
        </div>

        <div
            className='grid w-[192px] flex-shrink-0 grid-flow-col grid-rows-2 justify-between gap-x-2 px-2 pt-0.5'
            data-testid={testIds.manufacturerContainer}
        >
            <div className='truncate text-left font-semibold' data-testid={testIds.mfrPartNumber}>
                {data.mfrPartNumber}
            </div>
            <div
                className='truncate whitespace-nowrap text-left text-sm'
                data-testid={testIds.manufacturerName}
            >
                {data.manufacturer.name}
            </div>
            <div
                className='row-span-2 place-self-center md:hidden lg:hidden xl:hidden 2xl:hidden'
                data-testid={testIds.tooltipContainer}
            >
                <SourceAndDescriptionTooltip
                    source={data.dataAvailability.source}
                    description={data.description}
                />
            </div>
        </div>

        <div
            className='flex w-[100px] flex-shrink-0 items-center justify-center px-2 xs:hidden sm:hidden'
            data-testid={testIds.sourceContainer}
        >
            <SourceElement source={data.dataAvailability.source} />
        </div>

        <div
            className='description flex flex-grow items-center px-2 xs:hidden'
            data-testid={testIds.descriptionContainer}
        >
            <div className='line-clamp-2' data-testid={testIds.description}>
                {data.description}
            </div>
        </div>

        <div
            className='flex w-[92px] flex-shrink-0 items-center justify-center'
            data-testid={testIds.availabilityContainer}
        >
            <EcadIcons availabilityData={data.dataAvailability.availability} />
        </div>

        <div className='p-2' data-testid={testIds.downloadButtonContainer}>
            <DownloadButton datasheetUrl={data.datasheet} />
        </div>
    </div>
);
