/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import flowbitePlugin from 'flowbite/plugin';
import type { Config } from 'tailwindcss';
import colors from 'tailwindcss/colors';

export const SCREEN_BREAKPOINTS = {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
    X2L: '2xl',
} as const;

export default {
    content: [
        './index.html',
        './src/**/*.{ts,tsx}',
        'node_modules/flowbite-react/**/*.{js,jsx,ts,tsx}',
    ],
    theme: {
        colors: {
            'siemens-green-dark': '#647D28',
            'siemens-stone-dark': '#3C464B',
            'siemens-blue-7': '#005F87',
            'siemens-blue-10': '#197FA2',
            'siemens-stone-12': '#879BAA',
            'siemens-natural-blue-10': '#CDE6EB',
            'siemens-border-deafult-hover': '#8C8C8C',
            ...colors,
        },
        fontFamily: {
            sans: ['Segoe UI', 'sans-serif'],
        },
        fontSize: {
            sm: ['0.625rem', '1.3em'],
            base: ['0.75rem', '1.3em'],
            xl: '1rem',
            '2xl': '1.25rem',
            '3xl': '1.563rem',
            '4xl': '1.953rem',
            '5xl': '2.441rem',
        },

        screens: {
            [SCREEN_BREAKPOINTS.XS]: { min: '0px', max: '639px' },
            // => @media (min-width: 0px and max-width: 639px) { ... }

            [SCREEN_BREAKPOINTS.SM]: { min: '640px', max: '767px' },
            // => @media (min-width: 640px and max-width: 767px) { ... }

            [SCREEN_BREAKPOINTS.MD]: { min: '768px', max: '1023px' },
            // => @media (min-width: 768px and max-width: 1023px) { ... }

            [SCREEN_BREAKPOINTS.LG]: { min: '1024px', max: '1279px' },
            // => @media (min-width: 1024px and max-width: 1279px) { ... }

            [SCREEN_BREAKPOINTS.XL]: { min: '1280px', max: '1535px' },
            // => @media (min-width: 1280px and max-width: 1535px) { ... }

            [SCREEN_BREAKPOINTS.X2L]: { min: '1536px', max: '99999px' },
            // => @media (min-width: 1536px and max-width: 99999px) { ... }
        },
        extend: {},
    },
    plugins: [flowbitePlugin],
} satisfies Config;
