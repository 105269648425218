/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import { PdfIcon } from 'assets';

interface DownloadButtonProps {
    datasheetUrl: string;
}

export const DownloadButton = ({
    datasheetUrl,
    ...props
}: DownloadButtonProps &
    Omit<React.HTMLAttributes<HTMLAnchorElement>, 'children' | 'href' | 'target' | 'rel'>) => {
    return (
        <a {...props} href={datasheetUrl} target='_blank' rel='noreferrer'>
            <PdfIcon />
        </a>
    );
};
