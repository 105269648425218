/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
const PqLogoIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path d='M18 2H0V20H18V2Z' fill='#B5D1C7' />
        <path d='M20 0H2V18H20V0Z' fill='#1F3A31' />
        <path
            d='M5.97579 11.6935V14.1548H4.56245V7.15539H6.94686C7.63911 7.15539 8.19034 7.37012 8.60056 7.79957C9.01399 8.22902 9.2207 8.78666 9.2207 9.4725C9.2207 10.1583 9.01719 10.7 8.61018 11.0974C8.20316 11.4948 7.63911 11.6935 6.91802 11.6935H5.97579ZM5.97579 10.5157H6.94686C7.21607 10.5157 7.42438 10.4275 7.57181 10.2513C7.71923 10.075 7.79294 9.81862 7.79294 9.48211C7.79294 9.13278 7.71763 8.85557 7.567 8.65045C7.41637 8.44214 7.21446 8.33638 6.96128 8.33317H5.97579V10.5157ZM11.7493 7.67458V8.95331H12.4464V9.97726H11.7493V12.5588C11.7493 12.7639 11.7766 12.9049 11.8311 12.9818C11.8887 13.0587 11.9977 13.0972 12.1579 13.0972C12.2893 13.0972 12.3967 13.086 12.48 13.0635L12.4704 14.1211C12.2397 14.2077 11.9897 14.2509 11.7205 14.2509C10.8424 14.2509 10.3985 13.7478 10.3889 12.7414V9.97726H9.78796V8.95331H10.3889V7.67458H11.7493ZM14.6241 14.1548H13.2588V6.77081H14.6241V14.1548Z'
            fill='white'
        />
        <path d='M4 7V2H6V3H5V4H6V5H5V7H4ZM6 4V3H7V4H6Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M7 2H6V3H7V2Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M7 4H6V5H7V4Z' fill='#B5D1C7' />
        <path d='M9 7V5H8V3H9V5H10V3H11V5H10V6H11V7H9ZM9 3V2H10V3H9Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M9 2H8V3H9V2Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M9 5H8V6H9V5Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M11 2H10V3H11V2Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M11 5H10V6H11V5Z' fill='#B5D1C7' />
    </svg>
);

export default PqLogoIcon;
