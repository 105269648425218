/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export interface InitializationEvent {
    eventName: 'Initialization';
    eventData: {};
}

export const createInitializationEvent = (): InitializationEvent => ({
    eventName: 'Initialization',
    eventData: {},
});

export interface SearchEvent {
    eventName: 'Search';
    eventData: {
        'Event Source': 'SearchView';
        'Event Trigger': 'Unknown';
        'Event Category': 'Search';
        Mode: 'Part Number';
        Type: 'Initial';
        'Part Category': 'Unknown';
    };
}

export const createSearchEvent = (): SearchEvent => ({
    eventName: 'Search',
    eventData: {
        'Event Source': 'SearchView',
        'Event Trigger': 'Unknown',
        'Event Category': 'Search',
        Mode: 'Part Number',
        Type: 'Initial',
        'Part Category': 'Unknown',
    },
});

export interface DownloadEvent {
    eventName: 'Download';
    eventData: {
        'Event Source': 'SearchView';
        'Event Trigger': 'Drag&Drop';
        'Event Category': 'Download';
        'CAD Source': 'PQ Vault' | 'SamacSys' | 'Mixed' | 'None';
        'Part Category': string;
        Supplier: string;
        'Supplier Part Number': string;
        'Download Type': 'Part';
    };
}

export const createDownloadEvent = (
    data: Pick<
        DownloadEvent['eventData'],
        'CAD Source' | 'Part Category' | 'Supplier' | 'Supplier Part Number'
    >,
): DownloadEvent => ({
    eventName: 'Download',
    eventData: {
        'Event Source': 'SearchView',
        'Event Trigger': 'Drag&Drop',
        'Event Category': 'Download',
        ...data,
        'Download Type': 'Part',
    },
});

export interface RedirectEvent {
    eventName: 'Redirect';
    eventData: {
        'External Tool': 'PQ Portal';
    };
}

export const createRedirectEvent = (): RedirectEvent => ({
    eventName: 'Redirect',
    eventData: {
        'External Tool': 'PQ Portal',
    },
});

export interface FindChipsEvent {
    eventName: 'FindChips';
    eventData: {
        Type: 'Initial View' | 'External Site';
    };
}

export const createdFindChipsEvent = (
    type: FindChipsEvent['eventData']['Type'],
): FindChipsEvent => ({
    eventName: 'FindChips',
    eventData: {
        Type: type,
    },
});

export type AnalyticsEvent =
    | InitializationEvent
    | SearchEvent
    | DownloadEvent
    | RedirectEvent
    | FindChipsEvent;
