/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { ComponentProps } from 'react';

import { Search24Icon } from 'assets';
import Message from 'components/atoms/Message';

const EmptyMessage = (props: Omit<ComponentProps<typeof Message>, 'children'>) => {
    return (
        <Message {...props}>
            <div className='w-60'>
                <Search24Icon className='mx-auto mb-1' />
                <h2 className='text-[16px]'>Your search returned no results</h2>
                <p className='text-[14px] font-normal'>Try to refine your search query</p>
            </div>
        </Message>
    );
};

export default EmptyMessage;
