/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useState } from 'react';

import { apiRoot } from 'const';

import { useGetRegionQuery } from './regionService';

interface ApiRegionContextProviderProps {
    apiRegionMap: Record<string, string>;
}

interface ApiRegionContextType {
    apiRegionRoot: string;
    triggerUpdate: () => void;
}

export const ApiRegionContext = createContext<ApiRegionContextType>({
    apiRegionRoot: '',
    triggerUpdate: () => {},
});

export const ApiRegionContextProvider = ({
    apiRegionMap,
    children,
}: React.PropsWithChildren<ApiRegionContextProviderProps>) => {
    const [apiRegionRoot, setRegionApiRoot] = useState<string>('');
    const [initialized, setInitialized] = useState(false);
    const queryClient = useQueryClient();

    const retry = false;
    const { data: newRegionRoot, isLoading, isError } = useGetRegionQuery(apiRoot, retry);

    React.useEffect(() => {
        if (isError) {
            console.error('Error updating API region root: Request failed');
            return;
        }

        if (isLoading) {
            return;
        }

        if (initialized && !newRegionRoot) {
            console.error('Error updating API region root: Region data unavailable');
            return;
        }

        const apiRegionRootItem = apiRegionMap[newRegionRoot];

        if (!apiRegionRootItem) {
            console.error('Error getting API region url from configuration');
            return;
        }

        setRegionApiRoot(apiRegionRootItem);
        setInitialized(true);
    }, [newRegionRoot, isLoading, isError]);

    const triggerUpdate = () => {
        queryClient.invalidateQueries({ queryKey: ['getRegionQuery'] });
    };

    return (
        <ApiRegionContext.Provider value={{ apiRegionRoot, triggerUpdate }}>
            {children}
        </ApiRegionContext.Provider>
    );
};

export default ApiRegionContextProvider;
