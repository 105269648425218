/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

const useDebounce = <T>(initialState: T, debounceTimeMs = 1000) => {
    const [debouncedValue, setDebouncedValue] = React.useState(initialState);
    const [value, setValue] = React.useState<T>(initialState);

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (value !== debouncedValue) {
                setDebouncedValue(value);
            }
        }, debounceTimeMs);
        return () => clearTimeout(timeoutId);
    }, [value, debounceTimeMs]);

    const clear = () => {
        setValue(initialState);
        setDebouncedValue(initialState);
    };

    return { clear, debouncedValue, setDebouncedValue, value, setValue };
};

export default useDebounce;
