/*
 * Unpublished work. Copyright 2023 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
const PartQuestIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M28 4H0V32H28V4Z' fill='#B5D1C7' />
        <path d='M32 0H4V28H32V0Z' fill='#1F3A31' />
        <path
            d='M10.1048 16.6605V20.5283H7.88385V9.52926H11.6308C12.7186 9.52926 13.5848 9.86669 14.2295 10.5415C14.8791 11.2164 15.204 12.0927 15.204 13.1704C15.204 14.2482 14.8842 15.0993 14.2446 15.7238C13.605 16.3483 12.7186 16.6605 11.5855 16.6605H10.1048ZM10.1048 14.8097H11.6308C12.0538 14.8097 12.3812 14.6712 12.6128 14.3942C12.8445 14.1172 12.9603 13.7143 12.9603 13.1855C12.9603 12.6366 12.842 12.201 12.6053 11.8787C12.3686 11.5513 12.0513 11.3851 11.6534 11.3801H10.1048V14.8097ZM19.1775 10.3451V12.3546H20.2729V13.9636H19.1775V18.0203C19.1775 18.3426 19.2203 18.5642 19.3059 18.6851C19.3966 18.8059 19.5678 18.8664 19.8196 18.8664C20.0261 18.8664 20.1948 18.8487 20.3258 18.8135L20.3107 20.4754C19.9481 20.6114 19.5552 20.6794 19.1322 20.6794C17.7523 20.6794 17.0548 19.8887 17.0397 18.3074V13.9636H16.0954V12.3546H17.0397V10.3451H19.1775ZM23.695 20.5283H21.5496V8.92492H23.695V20.5283Z'
            fill='white'
        />
        <path d='M7 9V4H9V5H8V6H9V7H8V9H7ZM9 6V5H10V6H9Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M10 4H9V5H10V4Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M10 6H9V7H10V6Z' fill='#B5D1C7' />
        <path d='M12 9V7H11V5H12V7H13V5H14V7H13V8H14V9H12ZM12 5V4H13V5H12Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M12 4H11V5H12V4Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M12 7H11V8H12V7Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M14 4H13V5H14V4Z' fill='#B5D1C7' />
        <path opacity='0.5' d='M14 7H13V8H14V7Z' fill='#B5D1C7' />
    </svg>
);

export default PartQuestIcon;
