/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

import { PluginDataRequest, PluginDataResponse } from 'types/PluginData';

import { ApiRegionContext } from './ApiRegionContextProvider';
import apiRoutes from './apiRoutes';
import { api } from './helper';

export const useGetSearchQuery = (postData: PluginDataRequest) => {
    const { apiRegionRoot } = useContext(ApiRegionContext);
    const queryClient = useQueryClient();
    return useInfiniteQuery<PluginDataResponse>({
        queryKey: ['getSearch', postData.keywords, postData.nextPageToken],
        queryFn: async ({ pageParam = undefined }) => {
            await queryClient.cancelQueries({ queryKey: ['getSearch'] });
            const url = pageParam ? apiRoutes.postNextSearchPage : apiRoutes.postSearch;
            return api.postJson<PluginDataResponse>(apiRegionRoot, url, {
                ...postData,
                nextPageToken: pageParam,
            });
        },
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: postData.keywords.length >= 3,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => lastPage.nextPageToken,
    });
};
