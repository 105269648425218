/******************************************************************************
 Unpublished work. Copyright 2024 Siemens

 This material contains trade secrets or otherwise confidential information
 owned by Siemens Industry Software Inc. or its affiliates (collectively,
 "SISW"), or its licensors. Access to and use of this information is strictly
 limited as set forth in the Customer's applicable agreements with SISW.
******************************************************************************/
import resolveConfig from 'tailwindcss/resolveConfig';
import type { ResolvableTo, ScreensConfig } from 'tailwindcss/types/config';

import tailwindConfigFile, { SCREEN_BREAKPOINTS } from '../../tailwind.config';

const getTailwindCssConfig = () => {
    return resolveConfig(tailwindConfigFile);
};

interface IScreenSizeEntry {
    name: string;
    min: number;
    max: number;
}

const convertToScreenSizeModel = (screenConfig: ResolvableTo<ScreensConfig>) => {
    const arr: IScreenSizeEntry[] = [];
    Object.keys(screenConfig).forEach((key, i) => {
        const minPx = Object.values(screenConfig)[i].min as string;
        const min = Number.parseInt(minPx.replace('px', ''));
        const maxPx = Object.values(screenConfig)[i].max as string;
        const max = Number.parseInt(maxPx.replace('px', ''));
        arr.push({ name: key, min, max });
    });
    return arr;
};

const matchScreenBreakpoint = (screenSizeModel: IScreenSizeEntry[], screenWidth: number) => {
    if (screenSizeModel.length === 0) {
        return { name: SCREEN_BREAKPOINTS.SM, min: 0, max: 9999 };
    }
    const result = screenSizeModel.find((p) => p.min <= screenWidth && p.max >= screenWidth);
    if (!result) {
        throw new Error(`Not defined breakpoint for width ${screenWidth}`);
    }
    return result;
};

const testResolutionWithinBreakpoint = (
    width: number,
    breakpoint: (typeof SCREEN_BREAKPOINTS)[keyof typeof SCREEN_BREAKPOINTS],
) => {
    const config = resolveConfig(tailwindConfigFile);
    const { max: maxPx, min: minPx } = config.theme.screens[breakpoint];

    const min = Number.parseInt(minPx.replace('px', ''));
    const max = Number.parseInt(maxPx.replace('px', ''));

    expect(width).to.be.within(
        min,
        max,
        `Expected screen width ${width} to be within boundaries of breakpoint ${breakpoint} (${minPx} - ${maxPx})`,
    );

    return width;
};

export const tailwindCssUtil = {
    getTailwindCssConfig,
    convertToScreenSizeModel,
    matchScreenBreakpoint,
    testResolutionWithinBreakpoint,
};
