/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import { OnClickExpand } from 'assets';
import { DownloadButton } from 'components/atoms/DownloadButton/DownloadButton';
import DragButton from 'components/molecules/DragButton';
import { DrawerContext, DrawerIds } from 'components/molecules/Drawer';
import EcadIcons from 'components/molecules/EcadIcons';
import { SourceAndDescriptionTooltip } from 'components/molecules/SourceAndDescriptionTooltip/SourceAndDescriptionTooltip';
import { SourceElement } from 'components/molecules/SourceElement/SourceElement';
import { SupplyChainContext } from 'components/templates/SupplyChainDrawer/SupplyChainContext';
import type { PluginData } from 'types/PluginData';

const columnHelper = createColumnHelper<PluginData>();

export const SearchTableFieldIds = {
    DataAvailability: 'data-availability-field',
    Datasource: 'datasource-field',
    Description: 'description-field',
    Drag: 'drag-field',
    MfrName: 'mfrName-field',
    MfrPartNumber: 'mfr-part-number-field',
    PdfDownload: 'pdf-download-field',
    SupplyChain: 'supply-chain-field',
} as const;

export const SearchTableColumnIds = {
    Datasource: 'datasource-column',
    Description: 'description-column',
    Drag: 'drag-field-column',
    ECADData: 'ecad-data-column',
    MPN: 'mpn-column',
    MPNLight: 'mpn-light-column',
    PdfDownload: 'pdf-download-column',
    SupplyChain: 'supply-chain-column',
} as const;
export type SearchTableColumnsType =
    (typeof SearchTableColumnIds)[keyof typeof SearchTableColumnIds];

const testIds = {
    dragButton: 'search-table-drag-button',
} as const;

export { testIds as SearchTableTestIds };

const SupplyChainCell = ({ componentData }: { componentData: PluginData }) => {
    const { openDrawer, isOpenDrawer } = React.useContext(DrawerContext);
    const { currentComponent, setCurrentComponent } = React.useContext(SupplyChainContext);

    const drawerId = DrawerIds.SupplyChainDetails;

    const requestClickHandler = () => {
        setCurrentComponent(componentData);
        openDrawer(drawerId);
    };

    const isExpanded = () =>
        isOpenDrawer(drawerId) && componentData.mfrPartNumber === currentComponent?.mfrPartNumber;

    return (
        <>
            <div
                className='flex cursor-pointer select-none justify-center font-semibold text-siemens-blue-7 underline'
                onClick={requestClickHandler}
            >
                <span data-testid='supply-chain-button' className='text-siemens-blue-7'>
                    Details
                </span>
                {
                    <OnClickExpand
                        className={
                            (isExpanded() ? 'rotate-90' : '') +
                            ' h-5 w-4 fill-siemens-blue-7 stroke-siemens-blue-7 py-0.5'
                        }
                    />
                }
            </div>
        </>
    );
};

const columns = [
    columnHelper.accessor((row) => row.supplierPartNumber, {
        id: SearchTableColumnIds.Drag,
        cell: (info) => (
            <div data-testid={SearchTableFieldIds.Drag} className='grid justify-center'>
                <DragButton data-testid={testIds.dragButton} data={info.row.original} />
            </div>
        ),
        header: () => <span>&nbsp;</span>,
        size: 40,
        minSize: undefined,
        maxSize: undefined,
    }),
    columnHelper.accessor((row) => row.mfrPartNumber, {
        id: SearchTableColumnIds.MPN,
        cell: (info) => (
            <div className='grid min-w-[192px] grid-flow-col grid-rows-2 justify-between gap-x-2 px-2 pt-0.5'>
                <div
                    className='min-w-[152px] truncate text-left font-semibold'
                    data-testid={SearchTableFieldIds.MfrPartNumber}
                >
                    {info.getValue()}
                </div>
                <div
                    className='truncate whitespace-nowrap text-left text-sm font-normal'
                    data-testid={SearchTableFieldIds.MfrName}
                    title={info.row.original.manufacturer.name}
                >
                    {info.row.original.manufacturer.name}
                </div>
                <div className='row-span-2 place-self-center'>
                    <SourceAndDescriptionTooltip
                        source={info.row.original.dataAvailability.source}
                        description={info.row.original.description}
                    />
                </div>
            </div>
        ),
        header: () => <span className='block w-auto cursor-pointer'>MPN</span>,
        size: undefined,
        minSize: 192,
        maxSize: undefined,
    }),
    columnHelper.accessor((row) => row.mfrPartNumber, {
        id: SearchTableColumnIds.MPNLight,
        cell: (info) => (
            <div className='grid grid-flow-col grid-rows-2 justify-between gap-x-2 px-2 pt-0.5'>
                <div
                    className='truncate text-left font-semibold xs:w-[152px] md:w-[174px] lg:w-[174px] xl:w-[174px] 2xl:w-[174px]'
                    data-testid={SearchTableFieldIds.MfrPartNumber}
                >
                    {info.getValue()}
                </div>
                <div
                    className='truncate whitespace-nowrap text-left text-sm font-normal'
                    data-testid={SearchTableFieldIds.MfrName}
                >
                    {info.row.original.manufacturer.name}
                </div>
            </div>
        ),
        header: () => <span className='block w-auto cursor-pointer'>MPN</span>,
        size: 192,
        minSize: undefined,
        maxSize: undefined,
    }),
    columnHelper.accessor((row) => row.dataAvailability.source, {
        id: SearchTableColumnIds.Datasource,
        cell: (info) => (
            <div data-testid={SearchTableFieldIds.Datasource}>
                <SourceElement source={info.getValue()} />
            </div>
        ),
        header: () => <span>Data Source</span>,
        size: 100,
        minSize: undefined,
        maxSize: undefined,
    }),
    columnHelper.accessor((row) => row.description, {
        id: SearchTableColumnIds.Description,
        cell: (info) => (
            <div
                className='h-10 overflow-y-hidden text-ellipsis
                px-2
                pt-0.5 text-left sm:min-w-[162px] md:min-w-[174px]'
                data-testid={SearchTableFieldIds.Description}
                style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                }}
            >
                {info.getValue()}
            </div>
        ),
        header: () => <span className='block w-auto cursor-pointer'>Description</span>,
        size: undefined,
        minSize: 162,
        maxSize: undefined,
    }),
    columnHelper.accessor((row) => row.dataAvailability, {
        id: SearchTableColumnIds.ECADData,
        cell: (info) => (
            <div
                data-testid={SearchTableFieldIds.DataAvailability}
                className='grid justify-center py-2'
            >
                <EcadIcons availabilityData={info.row.original.dataAvailability.availability} />
            </div>
        ),
        header: () => <span>ECAD Data</span>,
        size: 92,
        minSize: undefined,
        maxSize: undefined,
    }),
    columnHelper.accessor((row) => row.mfrPartNumber, {
        id: SearchTableColumnIds.SupplyChain,
        cell: (info) => (
            <div data-testid={SearchTableFieldIds.SupplyChain}>
                <SupplyChainCell componentData={info.row.original} />
            </div>
        ),
        header: () => <span className='whitespace-nowrap'>Supply Chain</span>,
        size: 92,
        minSize: undefined,
        maxSize: undefined,
    }),
    columnHelper.accessor((row) => row.datasheet, {
        id: SearchTableColumnIds.PdfDownload,
        cell: (info) => (
            <div data-testid={SearchTableFieldIds.PdfDownload} className='grid justify-center'>
                <DownloadButton datasheetUrl={info.getValue()} />
            </div>
        ),
        header: () => <span>&nbsp;</span>,
        size: 40,
        minSize: undefined,
        maxSize: undefined,
    }),
];

const filterTableColumns = (names: SearchTableColumnsType[]) => {
    return columns.filter((p) => p.id && (names as string[]).includes(p.id));
};

export const searchTableXSColumns = filterTableColumns([
    SearchTableColumnIds.Drag,
    SearchTableColumnIds.MPN,
    SearchTableColumnIds.ECADData,
    SearchTableColumnIds.SupplyChain,
    SearchTableColumnIds.PdfDownload,
]);

export const searchTableSMColumns = filterTableColumns([
    SearchTableColumnIds.Drag,
    SearchTableColumnIds.MPN,
    SearchTableColumnIds.Description,
    SearchTableColumnIds.ECADData,
    SearchTableColumnIds.SupplyChain,
    SearchTableColumnIds.PdfDownload,
]);

export const searchTableMDColumns = filterTableColumns([
    SearchTableColumnIds.Drag,
    SearchTableColumnIds.MPNLight,
    SearchTableColumnIds.Datasource,
    SearchTableColumnIds.Description,
    SearchTableColumnIds.ECADData,
    SearchTableColumnIds.SupplyChain,
    SearchTableColumnIds.PdfDownload,
]);

export const searchTableLGColumns = filterTableColumns([
    SearchTableColumnIds.Drag,
    SearchTableColumnIds.MPNLight,
    SearchTableColumnIds.Datasource,
    SearchTableColumnIds.Description,
    SearchTableColumnIds.ECADData,
    SearchTableColumnIds.SupplyChain,
    SearchTableColumnIds.PdfDownload,
]);

export const searchTableXLColumns = filterTableColumns([
    SearchTableColumnIds.Drag,
    SearchTableColumnIds.MPNLight,
    SearchTableColumnIds.Datasource,
    SearchTableColumnIds.Description,
    SearchTableColumnIds.ECADData,
    SearchTableColumnIds.SupplyChain,
    SearchTableColumnIds.PdfDownload,
]);

export const searchTable2XLColumns = filterTableColumns([
    SearchTableColumnIds.Drag,
    SearchTableColumnIds.MPNLight,
    SearchTableColumnIds.Datasource,
    SearchTableColumnIds.Description,
    SearchTableColumnIds.ECADData,
    SearchTableColumnIds.SupplyChain,
    SearchTableColumnIds.PdfDownload,
]);

export const searchTableDefaultColumns = searchTableXSColumns;
