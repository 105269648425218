/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

import CloseButton from 'components/atoms/CloseButton';
import { ComponentVerticalInfo } from 'components/organisms/ComponentVerticalInfo/ComponentVerticalInfo';
import { DistributionChainInfo } from 'types/DistributionChainInfo';
import { PluginData } from 'types/PluginData';

import Drawer, { DrawerContext, DrawerIds } from '../../molecules/Drawer';
import { SupplyChainContext } from './SupplyChainContext';

const testIds = {
    topBar: 'supply-chain-top-bar',
    content: 'supply-chain-drawer-content',
    closeButton: 'supply-chain-drawer-close-button',
} as const;

export { testIds as supplyChainDrawerTestIds };

// All below is just a basic skeleton for the whole Supply Chain view, will be enhanced later

interface SupplyChainProps {
    component: PluginData;
    distributionChainInfo: DistributionChainInfo;
    closeHandler: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilteringForm = (props: SupplyChainProps) => {
    return <div>TODO Filtering form</div>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PricingResults = (props: SupplyChainProps) => {
    return <div>TODO Pricing results</div>;
};

const SupplyChainDetails = ({ closeHandler }: { closeHandler: () => void }) => {
    const { currentComponent } = React.useContext(SupplyChainContext);
    const isLoaded = !!currentComponent;

    if (!isLoaded) {
        return (
            <>
                <div className='divide-y divide-solid border-b border-b-gray-300'>
                    <div className='flex'>
                        <div className='ml-auto p-2 pl-4'>
                            <CloseButton data-testid={testIds.closeButton} onClick={closeHandler} />
                        </div>
                    </div>
                </div>

                <div>Loading</div>
            </>
        );
    }

    const subcomponentProps: SupplyChainProps = {
        component: currentComponent,
        distributionChainInfo: undefined as any,
        closeHandler,
    };

    return (
        <>
            <div className='divide-y divide-solid border-b border-b-gray-300'>
                <div className='flex'>
                    <ComponentVerticalInfo data={currentComponent} />

                    <div className='ml-auto p-2 pl-4'>
                        <CloseButton data-testid={testIds.closeButton} onClick={closeHandler} />
                    </div>
                </div>
            </div>

            <FilteringForm {...subcomponentProps} />
            <PricingResults {...subcomponentProps} />
        </>
    );
};

const SupplyChainDrawer = () => {
    const { isOpenDrawer, closeDrawer } = React.useContext(DrawerContext);
    const closeHandler = () => closeDrawer(DrawerIds.SupplyChainDetails);

    return (
        <Drawer
            isOpen={isOpenDrawer(DrawerIds.SupplyChainDetails)}
            onClose={closeHandler}
            widthStyle='w-[calc(100%-32px)]'
        >
            <div className='text-base' data-testid={testIds.content}>
                <SupplyChainDetails closeHandler={closeHandler} />
            </div>
        </Drawer>
    );
};

export default SupplyChainDrawer;
