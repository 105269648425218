/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

import { ApiRegionContextProvider } from 'api/ApiRegionContextProvider';
import { DrawerContextProvider } from 'components/molecules/Drawer';
import Layout from 'components/organisms/Layout';
import SearchView from 'components/templates/SearchView';
import { SupplyChainContextProvider } from 'components/templates/SupplyChainDrawer/SupplyChainContext';
import { apiRegionMap, isDevelopment } from 'const';

import SearchContextProvider from './SearchContextProvider';

const queryClient = new QueryClient();

const App = () => (
    <QueryClientProvider client={queryClient}>
        <ApiRegionContextProvider apiRegionMap={apiRegionMap}>
            <DrawerContextProvider>
                <SearchContextProvider>
                    <SupplyChainContextProvider>
                        <Layout>
                            <SearchView />
                        </Layout>

                        {
                            /* isDevelopment necessary for tree-shaking */
                            isDevelopment && <ReactQueryDevtools initialIsOpen={false} />
                        }
                    </SupplyChainContextProvider>
                </SearchContextProvider>
            </DrawerContextProvider>
        </ApiRegionContextProvider>
    </QueryClientProvider>
);

export default App;
