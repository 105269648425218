/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { flexRender } from '@tanstack/react-table';
import { ReactTableDevtools } from '@tanstack/react-table-devtools';
import React from 'react';

import EmptyMessage from 'components/molecules/EmptyMessage';
import ErrorMessage from 'components/molecules/ErrorMessage';
import FetchMessage from 'components/molecules/FetchMessage';
import StartMessage from 'components/molecules/StartMessage';
import { isDevelopment } from 'const';

import useSearchTable from './useSearchTable';

const testIds = {
    table: 'search-view-table',
} as const;

export { testIds as SearchViewTestIds };

const SearchView = () => {
    const { data, table, queryResult, paginationView } = useSearchTable();

    if (queryResult.isFetching) {
        return <FetchMessage />;
    }

    if (!!queryResult.error) {
        return <ErrorMessage />;
    }

    if (queryResult.fetchStatus === 'idle' && queryResult.isFetched && data?.length === 0) {
        return <EmptyMessage />;
    }

    if (queryResult.fetchStatus === 'idle' && data.length === 0) {
        return <StartMessage />;
    }

    return (
        <>
            <table
                data-testid={testIds.table}
                className='mb-[43px] w-full overflow-hidden overflow-y-scroll text-base text-siemens-stone-dark xs:w-[456px]'
            >
                <thead className='sticky top-0 bg-neutral-100'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        data-testid={header.id}
                                        key={header.id}
                                        style={{
                                            width: header.column.columnDef.size,
                                            minWidth: header.column.columnDef.minSize,
                                            maxWidth: header.column.columnDef.maxSize,
                                        }}
                                        className={
                                            'rounded-tl-lg rounded-tr-lg border-l px-2 py-1 text-left first:border-none'
                                        }
                                    >
                                        {header.isPlaceholder ? null : (
                                            <>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                            </>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                        <React.Fragment key={row.id}>
                            <tr
                                className={`border-b hover:bg-siemens-natural-blue-10 ${
                                    index % 2 ? 'bg-neutral-100' : 'bg-white'
                                }`}
                            >
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {paginationView}

            {
                /* isDevelopment necessary for tree-shaking */
                isDevelopment && <ReactTableDevtools table={table} />
            }
        </>
    );
};

export default SearchView;
