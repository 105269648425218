/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { Tooltip } from 'flowbite-react';
import React, { type ComponentProps } from 'react';

import { PluginSource } from 'types/PluginData';

import TooltipIcon from '../../../assets/TooltipIcon';

interface SourceAndDescriptionTooltipProps {
    source: PluginSource;
    description: string;
}

const testIds = {
    content: 'source-and-description-tooltip-content',
    icon: 'source-and-description-tooltip-icon',
    sourceContainer: 'source-and-description-tooltip-source-container',
    source: 'source-and-description-tooltip-source',
    descriptionContainer: 'source-and-description-tooltip-description-container',
    description: 'source-and-description-tooltip-description',
} as const;

export { testIds as SourceAndDescriptionTooltipTestIds };

export const SourceAndDescriptionTooltip = ({
    source,
    description,
    ...props
}: SourceAndDescriptionTooltipProps &
    Omit<ComponentProps<typeof Tooltip>, 'content' | 'className'>) => (
    <Tooltip
        {...props}
        className='px-2 py-1 shadow-md'
        content={
            <div className='max-w-[152px] overflow-hidden bg-red-600' data-testid={testIds.content}>
                {source !== 'None' && (
                    <div data-testid={testIds.sourceContainer}>
                        <h3 className='font-bold'>Data Source:</h3>
                        <p data-testid={testIds.source}>{source}</p>
                    </div>
                )}

                <div
                    data-testid={testIds.descriptionContainer}
                    className='sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden'
                >
                    <h3 className='mt-1 font-bold'>Description:</h3>
                    <p data-testid={testIds.description}>{description}</p>
                </div>
            </div>
        }
        style='light'
        placement='left'
    >
        <TooltipIcon data-testid={testIds.icon} className='cursor-pointer' />
    </Tooltip>
);
