/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
export default {
    postSearch: '/api/v3/search/mpn/lite',
    postNextSearchPage: '/api/v3/search/nextPage/lite',
    getDownloadKey: '/api/v3/getDownloadKey',
    postInitCacheForDownload: '/api/v3/initCacheForDownload',
    getExtractRegion: '/api/v3/extract/region',
    postGetDistributionChainInfo: '/api/v3/search/getDistributionChainInfo',
};
