/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';

const Message = (props: Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>) => {
    return (
        <div
            {...props}
            className='mt-[calc(50vh-120px)] flex max-w-full select-none justify-center text-center text-siemens-stone-12'
        >
            {props.children}
        </div>
    );
};

export default Message;
